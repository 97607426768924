import { SVGProps } from "react";

export default function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="property-1-snare-drum__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#property-1-snare-drum__a)">
        <path d="M48 27v-3c0-1.654-1.346-3-3-3h-7.661c.134-.084.257-.182.368-.293.575-.574.793-1.379.598-2.205-.341-1.441-1.831-2.731-3.988-3.451-1.338-.445-1.338-.445-3.452-2.09a319.207 319.207 0 0 0-3.359-2.584 498.59 498.59 0 0 0 10.05-6.545C37.833 3.646 38 3.334 38 3c0-1.654-1.346-3-3-3a.993.993 0 0 0-.581.186A731.409 731.409 0 0 0 24 7.751 725.66 725.66 0 0 0 13.581.186.993.993 0 0 0 13 0c-1.654 0-3 1.346-3 3 0 .334.167.646.445.832a501.467 501.467 0 0 0 10.05 6.545 308.877 308.877 0 0 0-3.359 2.584c-2.115 1.645-2.115 1.645-3.453 2.09-2.157.72-3.648 2.01-3.988 3.451-.195.826.023 1.631.598 2.205.111.111.235.209.368.293H3c-1.654 0-3 1.346-3 3v3h2v15H0v3c0 1.654 1.346 3 3 3h42c1.654 0 3-1.346 3-3v-3h-2V27h2ZM33.684 16.949c1.885.627 2.567 1.561 2.675 2.013.051.215-.025.29-.065.331-.028.028-.14.082-.355.082-.866 0-2.362-.83-2.984-2.678.208.076.445.157.729.252ZM35.27 2.037c.269.074.491.26.618.502A479.434 479.434 0 0 1 25.797 9.09l-.121-.091a727.744 727.744 0 0 1 9.594-6.962Zm-23.158.502c.127-.242.35-.428.619-.502a725.221 725.221 0 0 1 9.594 6.962l-.122.091a482.075 482.075 0 0 1-10.091-6.551Zm-.47 16.423c.106-.452.79-1.386 2.674-2.013.283-.095.52-.176.73-.252-.622 1.847-2.118 2.678-2.984 2.678-.216 0-.327-.054-.355-.082-.04-.041-.116-.116-.065-.331Zm5.306-1.645c.236-.704.236-.704 2.448-1.999A239.859 239.859 0 0 0 24 12.562a236.729 236.729 0 0 0 4.604 2.756c2.213 1.295 2.213 1.295 2.448 1.998.626 1.879 1.927 3.111 3.254 3.684H13.694c1.328-.572 2.628-1.805 3.254-3.683ZM46 45c0 .551-.448 1-1 1H3c-.551 0-1-.449-1-1v-1h44v1ZM15 29v-2h18v2h-2v11h2v2H15v-2h2V29h-2Zm20 0v-2h9v15h-9v-2h2V29h-2Zm0 9h-2v-7h2v7Zm-20 0h-2v-7h2v7Zm-2-9h-2v11h2v2H4V27h9v2ZM2 25v-1c0-.551.449-1 1-1h42c.552 0 1 .449 1 1v1H2Z" />
      </g>
    </svg>
  );
}
