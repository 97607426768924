enum Instrument {
  // gtrs
  gtrAccFingerPicked = "gtr_acc_fngrpck",
  gtrAccStrummed = "gtr_acc_strum",
  gtrAccLead = "gtr_acc_lead",

  //basses
  gtrBass = "gtr_bass",

  // drums
  drmSet = "drm_set",
  drmKick = "drm_kick",
  drmSnare = "drm_snare",
  drmOh = "drm_oh",

  // synths
  synthPad = "synth_pad",
  synthLead = "synth_lead",

  // percussion
  percTambo = "perc_tambo",
  percShaker = "perc_shaker",
  percBongos = "perc_bongos",

  // string
  stringEnsemble = "string_ensemble",

  // vox
  voxFemale = "vox_female",
}

export default Instrument;

export function isInstrument(value: any): value is Instrument {
  return Object.values(Instrument).includes(value);
}
