import { SVGProps } from "react";

export default function Volume(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 80 80"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M32.729 6v11.823h-2.644V6h2.644ZM79 41.47V25.707c0-2.177-1.776-3.941-3.966-3.941H4.966C2.776 21.766 1 23.53 1 25.706v15.766c0 2.176 1.776 3.94 3.966 3.94h70.068c2.19 0 3.966-1.764 3.966-3.94Zm-3.966-17.078c.73 0 1.322.588 1.322 1.314V41.47c0 .725-.592 1.313-1.322 1.313H4.966c-.73 0-1.322-.588-1.322-1.313V25.706c0-.726.592-1.314 1.322-1.314h70.068ZM79 69.06V53.294c0-2.176-1.776-3.941-3.966-3.941H4.966C2.776 49.353 1 51.117 1 53.294V69.06C1 71.236 2.776 73 4.966 73h70.068C77.224 73 79 71.236 79 69.059Zm-3.966-17.08c.73 0 1.322.589 1.322 1.314V69.06c0 .725-.592 1.314-1.322 1.314H4.966c-.73 0-1.322-.589-1.322-1.314V53.294c0-.725.592-1.314 1.322-1.314h70.068ZM6.288 32.275h42.305v-2.628c0-.725.592-1.314 1.322-1.314h14.543c.73 0 1.322.589 1.322 1.314v2.628h7.932v2.627H65.78v2.627c0 .726-.592 1.314-1.322 1.314H49.915c-.73 0-1.322-.588-1.322-1.314v-2.627H6.288v-2.627Zm7.932 27.588H6.288v2.627h7.932v2.628c0 .725.592 1.313 1.322 1.313h14.543c.73 0 1.322-.588 1.322-1.313V62.49h42.305v-2.627H31.407v-2.628c0-.725-.592-1.313-1.322-1.313H15.542c-.73 0-1.322.588-1.322 1.313v2.628Zm39.661-42.04V6h-2.644v11.823h2.644ZM11.576 6v11.823H8.932V6h2.644Zm52.882 11.823V6h-2.644v11.823h2.644ZM22.153 6v11.823h-2.644V6h2.643Zm21.152 11.823V6h-2.644v11.823h2.644ZM69.745 6v11.823h-2.643V6h2.644ZM27.442 17.823V6h-2.644v11.823h2.644ZM48.593 6v11.823H45.95V6h2.644ZM59.17 17.823V6h-2.644v11.823h2.645ZM16.864 6v11.823H14.22V6h2.644Zm21.153 11.823V6h-2.644v11.823h2.644Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
