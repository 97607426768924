import { SVGProps } from "react";

export default function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 48 48"
      {...props}
    >
      <path d="M9 16H7v-2h2v2Zm36-5H31v2h14v-2ZM9 17H7v2h2v-2Zm36-3H31v2h14v-2ZM5 11H3v2h2v-2Zm8 6h-2v2h2v-2ZM48 6v35h-1.998v1H2v-1H0V6h48ZM8 33H7v-9H4v16h4v-7Zm6 0h-1v-9h-2v9h-1v7h4v-7Zm6-9h-3v9h-1v7h4V24Zm6 9h-1v-9h-2.999v16H26v-7Zm6 0h-1v-9h-2v9h-1v7h4v-7Zm6 0h-1v-9h-2v9h-1v7h4v-7Zm6.002-9H41v9h-1v7h4.002V24ZM46 8H2v14h44V8ZM5 17H3v2h2v-2Zm0-3H3v2h2v-2Zm12 3h-2v2h2v-2Zm-4-6h-2v2h2v-2Zm0 3h-2v2h2v-2Zm4 0h-2v2h2v-2Zm-8-3H7v2h2v-2Zm36 6H31v2h14v-2Zm-28-6h-2v2h2v-2Zm12 8H19v-8h10v8Zm-2-6h-6v4h6v-4Z" />
    </svg>
  );
}
