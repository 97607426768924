import { SVGProps } from "react";

export default function Pan(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 80 80"
      {...props}
    >
      <path d="M37.244 8.614a1.306 1.306 0 0 1-.398-.966V1.363c0-.37.133-.701.398-.966.266-.265.584-.397.97-.397h4.18c.372 0 .703.132.969.397.265.265.398.595.398.966v1.919h-2.429V2.236h-2.057v4.539h2.057V5.452h2.43v2.196c0 .384-.134.702-.399.966a1.335 1.335 0 0 1-.969.397h-4.18c-.386 0-.704-.132-.97-.397ZM7.251 61.989h2.43v6.775h3.424V71H7.25v-9.011Zm66.711 0c.371 0 .703.132.969.397.265.264.398.595.398.966v2.765c0 .384-.133.702-.398.966a1.335 1.335 0 0 1-.97.397v.344c.226 0 .439.08.624.212a1 1 0 0 1 .372.53L75.7 71h-2.654l-.982-3.07h-1.221V71h-2.43v-9.011h5.549ZM72.9 65.588v-1.363h-2.057v1.363H72.9ZM9.919 27.715a2.966 2.966 0 0 1-2.96 2.974A2.966 2.966 0 0 1 4 27.715a2.966 2.966 0 0 1 2.96-2.973 2.966 2.966 0 0 1 2.959 2.973Zm67.081 0a2.966 2.966 0 0 1-2.96 2.974 2.966 2.966 0 0 1-2.959-2.974 2.966 2.966 0 0 1 2.96-2.973A2.966 2.966 0 0 1 77 27.715Z" />
      <path
        fillRule="evenodd"
        d="M67.135 41.59c0 14.778-11.925 26.758-26.635 26.758S13.865 56.368 13.865 41.59c0-14.112 10.873-25.672 24.662-26.686v11.709c0 .609.495 1.102 1.106 1.102h.747c.611 0 1.106-.493 1.106-1.102V14.85c14.254.521 25.649 12.294 25.649 26.74Zm-30.647 7.86a1.74 1.74 0 0 1-.531-1.289v-8.38c0-.494.177-.935.53-1.288.355-.353.78-.53 1.293-.53h5.574c.496 0 .938.177 1.292.53.354.353.531.794.531 1.288v8.38c0 .512-.177.936-.53 1.288a1.78 1.78 0 0 1-1.293.53H37.78a1.75 1.75 0 0 1-1.292-.53Zm5.45-2.453v-6.052h-2.743v6.052h2.743Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
