import { ReactNode } from "react";
import IconCompressor from "ui/icons/techniques/compressor";
import IconEQ from "ui/icons/techniques/eq";
import IconPan from "ui/icons/techniques/pan";
import IconVolume from "ui/icons/techniques/volume";
import { Difficulty } from "./Difficulty";
import { Technique } from "./technique";
import { Game } from "./types/Game";

export interface GamesByTechnique {
  technique: Technique;
  techniqueShort: string;
  name: string;
  icon: (props: { className: string }) => ReactNode;
  games: {
    [game: string]: GameDescription;
  };
}

export type AllTechniqueGameDescriptions = {
  [technique in Technique]: GamesByTechnique;
};

export interface BaseGameDescription {
  title: string;
  shortTitle: string;
  description: {
    [difficulty in Difficulty]: string;
  };
  points: {
    [difficulty in Difficulty]: number;
  };
  pointsWhenCompleted: {
    [difficulty in Difficulty]: number;
  };
}

export interface GameDescription extends BaseGameDescription {
  game: Game;
  technique: Technique;
}

export type PointsByDifficulty = {
  [difficulty in Difficulty]: number;
};

const defaultPoints: PointsByDifficulty = {
  easy: 1,
  medium: 2,
  hard: 3,
};

const pointsWhenCompleted = defaultPoints;

const allTechniqueGameDescriptions: AllTechniqueGameDescriptions = {
  eq: {
    name: "Equalizer",
    technique: Technique.eq,
    techniqueShort: "EQ",
    icon: ({ className }) => <IconEQ className={className} />,
    games: {
      // Config set ui/templates/Game/EQ/FrequencyFinder/constants.tsx
      [Game.freq_finder_lopass]: {
        title: "Frequency Finder // Low Pass",
        shortTitle: "Freq Finder//Lo-Pass",
        game: Game.freq_finder_lopass,
        technique: Technique.eq,
        points: defaultPoints,
        pointsWhenCompleted,
        description: {
          easy: "A 3 octave guessing range.",
          medium: "A 2 octave guessing range.",
          hard: "A 1 octave guessing range.",
        },
      },

      // Config set ui/templates/Game/EQ/FrequencyFinder/constants.tsx
      [Game.freq_finder_hipass]: {
        title: "Frequency Finder // High Pass",
        shortTitle: "Freq Finder//Hi-Pass",
        game: Game.freq_finder_hipass,
        technique: Technique.eq,
        points: defaultPoints,
        pointsWhenCompleted,
        description: {
          easy: "A 3 octave guessing range.",
          medium: "A 2 octave guessing range.",
          hard: "A 1 octave guessing range.",
        },
      },

      // Config set ui/templates/Game/EQ/FrequencyFinder/constants.tsx
      [Game.freq_finder_boost]: {
        title: "Frequency Finder // Boosts",
        shortTitle: "Freq Finder//Boosts",
        game: Game.freq_finder_boost,
        technique: Technique.eq,
        points: defaultPoints,
        pointsWhenCompleted,
        description: {
          easy: "15 DB boosts and a 3 octave guessing range.",
          medium: "8 DB boosts and a 2 octave guessing range.",
          hard: "3 DB boosts and a 1 octave guessing range.",
        },
      },

      // Config set ui/templates/Game/EQ/FrequencyFinder/constants.tsx
      [Game.freq_finder_cut]: {
        title: "Frequency Finder // Cuts",
        shortTitle: "Freq Finder//Cuts",
        game: Game.freq_finder_cut,
        technique: Technique.eq,
        points: defaultPoints,
        pointsWhenCompleted,
        description: {
          easy: "15 DB cuts and a 3 octave guessing range.",
          medium: "8 DB cuts and a 2 octave guessing range.",
          hard: "3 DB cuts and a 1 octave guessing range.",
        },
      },
    },
  },
  compression: {
    name: "Compression",
    technique: Technique.compression,
    techniqueShort: "Comp",
    icon: ({ className }) => <IconCompressor className={className} />,
    games: {
      [Game.is_this_compressor_on]: {
        title: "Is This Compressor On?",
        shortTitle: "Is The Comp On?",
        game: Game.is_this_compressor_on,
        technique: Technique.compression,
        points: defaultPoints,
        pointsWhenCompleted,
        description: {
          easy: "7 DB minimum difference in gain reduction between sources.",
          medium: "4 DB minimum difference in gain reduction between sources.",
          hard: "1 DB minimum difference in gain reduction between sources.",
        },
      },
    },
  },
  pan: {
    name: "Pan",
    technique: Technique.pan,
    techniqueShort: "Pan",
    icon: ({ className }) => <IconPan className={className} />,
    games: {
      [Game.can_you_pan]: {
        title: "Can You Pan?",
        shortTitle: "Can You Pan?",
        game: Game.can_you_pan,
        technique: Technique.pan,
        points: defaultPoints,
        pointsWhenCompleted,
        description: {
          easy: "Available options are in 30 degree steps.",
          medium: "Available options are in 15 degree steps.",
          hard: "Available options are in 6 degree steps.",
        },
      },
    },
  },
  volume: {
    name: "Volume",
    technique: Technique.volume,
    techniqueShort: "Vol",
    icon: ({ className }) => <IconVolume className={className} />,
    games: {
      [Game.fader_grader]: {
        title: "Fader Grader",
        shortTitle: "Fader Grader",
        game: Game.fader_grader,
        technique: Technique.volume,
        points: defaultPoints,
        pointsWhenCompleted,
        description: {
          easy: "Available options are in 5 DB steps.",
          medium: "Available options are in 3 DB steps.",
          hard: "Available options are in 1 DB steps.",
        },
      },
    },
  },

  mix_puzzle_technique: {
    name: "MixPuzzle",
    technique: Technique.mixPuzzle,
    techniqueShort: "MixPuzzle",
    icon: ({ className }) => <div className={className} />,
    games: {
      [Game.mixPuzzle]: {
        title: "MixPuzzle",
        shortTitle: "MixPuzzle",
        game: Game.mixPuzzle,
        technique: Technique.mixPuzzle,
        points: {
          easy: 3,
          medium: 4,
          hard: 5,
        },
        pointsWhenCompleted: {
          easy: 0,
          medium: 0,
          hard: 0,
        },
        description: {
          easy: "Compression is excluded. See the correct status of each track as you mix.",
          medium:
            "All techniques included. See the correct status of each track as your mix.",
          hard: "All techniques included. No realtime feedback. You have to use your ears!",
        },
      },
    },
  },
};

export const descriptionsByGame: {
  [game in Game]: GameDescription;
} = Object.values(allTechniqueGameDescriptions).reduce(
  (acc, { games }) => ({ ...acc, ...games }),
  {} as { [game in Game]: GameDescription }
);

export default allTechniqueGameDescriptions;
