export enum Game {
  freq_finder_lopass = "freq_finder_lopass",
  freq_finder_hipass = "freq_finder_hipass",
  freq_finder_boost = "freq_finder_boost",
  freq_finder_cut = "freq_finder_cut",

  is_this_compressor_on = "is_this_compressor_on",
  fader_grader = "fader_grader",
  can_you_pan = "can_you_pan",

  mixPuzzle = "mix_puzzle_game",
}

export function isGame(value: any): value is Game {
  return Object.values(Game).includes(value);
}

export type GameAndMixPuzzle = Game | "mixpuzzle";
