import { SVGProps } from "react";

export default function EQ(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 80 80"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M5.952 14C3.217 14 1 16.175 1 18.857v41.286C1 62.825 3.217 65 5.952 65h8.667V40.107H1.001L1 38.893v-1.214h13.62V14h2.476v23.506c.954-.279 1.782-.889 2.573-1.781 1.149-1.297 2.103-3.057 3.142-4.988l.04-.073c.991-1.844 2.067-3.845 3.423-5.375 1.16-1.309 2.597-2.355 4.441-2.682V14h2.477v8.607c1.844.327 3.28 1.373 4.441 2.682 1.355 1.53 2.432 3.53 3.424 5.375l.039.073c1.039 1.93 1.993 3.691 3.142 4.988.79.892 1.619 1.502 2.572 1.781V14h2.477v23.68h13.619V14h2.476v23.68H79v2.427H65.38V65h8.667C76.783 65 79 62.825 79 60.143V18.857C79 16.175 76.783 14 74.048 14H5.952Zm56.953 26.107h-13.62V65h13.62V40.107ZM46.809 40c-1.844-.327-3.28-1.373-4.441-2.682-1.355-1.53-2.432-3.53-3.424-5.374l-.039-.074c-1.039-1.93-1.993-3.691-3.142-4.987-.79-.893-1.619-1.503-2.572-1.782V65h13.618V40ZM30.715 25.101c-.953.28-1.781.89-2.572 1.782-1.15 1.296-2.104 3.057-3.142 4.987l-.04.073c-.991 1.844-2.068 3.845-3.423 5.375-1.16 1.31-2.597 2.355-4.442 2.682v25h13.62V25.101Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
