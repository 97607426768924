import { SVGProps } from "react";

export default function Compressor(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 80 80"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M1 17.857C1 15.727 2.717 14 4.836 14h70.328C77.282 14 79 15.727 79 17.857v46.286C79 66.273 77.282 68 75.164 68H4.836C2.717 68 1 66.273 1 64.143V17.857Zm39.417 39.857c-.866-3.685-4.159-6.428-8.09-6.428-.764 0-1.504.103-2.207.298l-9.496-16.539c3.318-1.125 7.08-1.76 11.065-1.76 7.796 0 14.734 2.43 19.18 6.207v-2.543a23.751 23.751 0 0 0-.898-.624c-4.767-3.154-11.237-5.04-18.282-5.04-6.86 0-13.174 1.788-17.902 4.793v2.414c1.63-1.176 3.523-2.19 5.615-3.003L28.9 52.028a8.364 8.364 0 0 0-4.66 5.686h-9.173c-2.119 0-3.837-1.727-3.837-3.857V28.143c0-2.13 1.718-3.857 3.837-3.857H49.59c2.119 0 3.836 1.727 3.836 3.857v25.714c0 2.13-1.717 3.857-3.836 3.857h-9.173Zm30.91-27c0 3.55-2.862 6.429-6.393 6.429-3.53 0-6.393-2.878-6.393-6.429 0-1.699.656-3.244 1.727-4.394-.11-.622-.092-1.172.19-1.456.35-.352 1.107-.295 1.902-.094l.083.022a6.343 6.343 0 0 1 2.491-.506c3.531 0 6.394 2.878 6.394 6.428Zm-6.393 27c3.531 0 6.394-2.878 6.394-6.428a6.425 6.425 0 0 0-1.734-4.402c.117-.638.104-1.205-.184-1.495-.35-.352-1.106-.295-1.901-.094l-.151.04a6.345 6.345 0 0 0-2.424-.478c-3.53 0-6.393 2.878-6.393 6.429 0 3.55 2.862 6.428 6.393 6.428Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
