import classNames from "classnames";
import Navbar, { Props as NavbarProps } from "ui/organisms/Navbar/Navbar";
import { AuthState } from "util/useAuth/types";

interface SharedProps {
  auth: AuthState;
  className?: string;
  bgClassName?: string;
  navbarProps?: Partial<NavbarProps>;
}

interface GeneralPageLayoutProps extends SharedProps {
  children:
    | ((props: { className: string }) => React.ReactNode)
    | React.ReactNode;
}

export const generalInnerSizeMaxWidthWrapper = "max-w-6xl mx-auto";
const innerSizeWrapperClassName = `${generalInnerSizeMaxWidthWrapper} px-4`;

export function GeneralPageLayout({
  auth,
  className,
  bgClassName = "bg-slate-950",
  children,
  navbarProps,
}: GeneralPageLayoutProps) {
  return (
    <>
      <Navbar auth={auth} {...navbarProps} />

      <main
        className={classNames("relative min-h-screen", bgClassName, className)}
      >
        {typeof children === "function" ? (
          children({ className: innerSizeWrapperClassName })
        ) : (
          <div className={innerSizeWrapperClassName}>{children}</div>
        )}
      </main>
    </>
  );
}

interface NoExtrasPageLayout extends SharedProps {
  children: React.ReactNode;
}

export function SimplePageLayout({
  auth,
  navbarProps,
  className,
  children,
}: NoExtrasPageLayout) {
  return (
    <>
      <Navbar auth={auth} {...navbarProps} />

      <main
        className={classNames("relative bg-slate-950 min-h-screen", className)}
      >
        {children}
      </main>
    </>
  );
}
